@import "~src/style/variables.less";

.k-dashboard {
	display: flex;

	.k-panel-group {
		display: flex;
	}

	.k-panel {
		display: flex;
		flex-direction: column;
		min-width: 0;

		> header {
			display: flex;
			flex-shrink: 0;
			align-items: stretch;
			padding: 0.5em 0.6em;
			border-bottom: 1px dotted #ccc;
			flex-wrap: wrap;

			label {
				flex: 1;
				margin: 0;
				font-size: 1.2em;
				padding: 0.2em;

				.navbar-tag {
					font-weight: 100;
					line-height: inherit;
					vertical-align: bottom;
					opacity: 0.8;
				}
			}

			.btn + label {
				margin-left: 0.2em;
			}

			aside.controls {
				display: flex;
				flex-wrap: wrap;

				a {
					font-size: 11px;
					line-height: 18px;
					margin-left: 0.3em;
				}
			}

			aside.fixed-height-toggle {
				min-width: 2em;
				display: flex;
				margin-left: 0.5em;
				border-radius: 2px;
				border: 1px solid transparent;
				color: #a7a7a7;
				cursor: pointer;

				i.fa {
					margin: auto;
				}
			}
			aside.fixed-height-toggle:hover {
				background-color: #e7e7e7;
				color: #7c7c7c;
			}
			aside.fixed-height-toggle.active {
				background-color: #dfdfdf;
				color: #161616;
				border-color: #b9b9b9;
			}
		}

		> main {
			min-height: 0;

			.k-linkset {
				display: flex;

				.icon {
					margin-right: 0.2em;
				}
			}

			footer.main {
				display: none;
			}

			.alert {
				margin-bottom: 0;
				border-bottom: none;
			}

			.form.edit-mode {
				.form-footer {
					display: block;
				}
			}

			.report-title {
				display: none;
			}

			.report-header {
				display: none;
			}

			.report-footer {
				display: none;
			}

			.report-table {
				border-top: none;

				table {
					width: 100%;
					margin-bottom: 10px;
				}

				tr.report-total td, tr.report-total-row td {
					font-weight: bold;
					font-size: 100%;
				}
			}

			.chart-container {
				display: flex;
				height: 100%;
				text-align: center;

				.no-data {
					flex: 1;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					span, h3 {
						color: #ccc;
					}

					span.fa {
						font-size: 3em;
					}

					h3 {
						font-size: 1.2em;
						font-weight: normal;
					}
				}
			}

			.scroll-container {
				flex-grow: 1;
			}
		}

		&.iframe {
			main {
				overflow: hidden;
				padding: 0;

				iframe {
					border: 0;
					width: 100%;
					height: 100%;
				}
			}
		}

		&.link-list {
			.nav.nav-pills {
				margin-bottom: 0;
			}
		}

		&.text {
			main {
				opacity: 0.9;

				p {
					margin-bottom: 1em;
					line-height: 1.5em;
				}

				h1, h2, h3, h4, h5, h6 {
					font-weight: normal;
					line-height: normal;
					margin-bottom: 0.4em;
				}

				h1 {
					font-size: 1.1em;
				}

				h2 {
					font-size: 1.2em;
				}

				h3 {
					font-size: 1.3em;
				}

				h4 {
					font-size: 1.4em;
				}

				h5 {
					font-size: 1.5em;
				}

				h6 {
					font-size: 1.6em;
				}
			}
		}

		&.list {
			min-height: 12em;

			section.main {
				> .body {
					border: 0;
					padding: 0;
				}
			}
		}

		&.report-query {
			> main {
				overflow: initial;
			}

			section.main {
				min-height: 0;
			}

			.report-chart {
				overflow: auto;
			}

			.grid {
				padding: 1px;
			}

			.alert.empty {
				border: none;
				background: white;
				color: #888;
			}
		}
	}

	.k-panel:where(:not(.form):not(.iframe)) {
		> main {
			flex: 1;
			padding: 2px;
			overflow: auto;

			header.main {
				display: none;
			}

			section.main {
				border: 0;

				.form-body {
					padding: 0;
				}
			}
		}
	}

	.k-panel.list {
		> main {
			header.main {
				//elements naturally fall into order group 0, so this
				//will push the header out past them
				order: 1;
				display: flex;
				background: transparent;

				> label {
					opacity: 0;
				}

				.btn {
					padding: 0 6px;
					font-size: 10.5px;

					&.help {
						display: none;
					}
				}
			}
		}
	}

	.k-panel.parameter-form {
		main form {
			ul {
				display: flex;

				li {
					flex: 1;
					padding: 0 5px;
					margin: 0;
				}
				li:first-child {
					padding-left: 0;
				}
				li:last-child {
					flex: 0;
					display: flex;
					align-items: flex-end;
					padding-right: 0;

					button {
						margin-bottom: 1px;
					}
					button + button {
						margin-left: 5px;
					}
				}
				li.required {
					label {
						font-weight: bold;

						em {
							color: red;
						}
					}
				}
				li:not(.required) {
					label {
						em {
							display: none;
						}
					}
				}
			}

			.select2-button-container {
				display: none;
			}
		}
	}
	.k-panel.parameter-form:not(.customize-mode) {
		> header {
			display: none;
		}
	}

	.k-panel.databox-group {
		--size: 150px;
		--gap: 10px;

		main {
			display: flex;
			flex-wrap: wrap;
			padding: var(--gap);
		}

		.databox {
			--padding: 0.5rem;

			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: var(--size);
			width: var(--size);
			margin: var(--gap);
			box-shadow: 1px 0px 9px 0px #00000047;
			border-radius: 3px;

			label {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 4;
				overflow: hidden;
				font-size: 15px;
				margin: 0;
				padding: var(--padding);
			}

			section {
				display: flex;
				flex-direction: column;
			}

			em {
				font-size: 38pt;
				font-style: normal;
				line-height: normal;
				padding: 0 var(--padding);
			}
			em.large {
				font-size: 32pt;
			}

			.list-name {
				font-size: 9pt;
				padding: var(--padding);
			}
		}
		.databox.dark {
			color: #ffffffeb;

			.list-name {
				background: #ffffff1c;
				color: #ffffffde;
			}
		}
		.databox.bright {
			border: 1px solid #00000014;
			color: #7c7c7c;

			.list-name {
				background: #ffffff52;
				color: #00000094;
			}
		}
	}
}

.k-dashboard.sticky-header {
	.k-panel-group:first-of-type {
		position: sticky;
		top: 0;
		z-index: 5;

		.k-panel {
			margin: 0;
			margin-top: @gutter-large * -1;
			margin-bottom: @gutter-large;
			background-color: #f0f0f0;
			border-radius: 0;
			border-color: #a7a7a7;
			border-width: 0 1px 1px;
		}
	}
}

header.dashboard-navigation {
	background-color: #f0f0f0;
	border-top: 1px solid #bfb9b9;

	main {
		padding: 0.3em;
	}

	.k-linkset {
		display: flex;
	}
}
