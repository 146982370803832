.dashboard-container {
  flex: 1;
  background-color: #ebeff2;
  box-shadow: inset 0px 0px 6px -2px #3e3e3e;
  border-top: 1px solid #a2a2a2;
  padding: 10px;
  overflow: auto;
}
.dashboard-container .k-panel {
  margin: 10px;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #cbcbcb;
  box-shadow: 0 0 4px 0px #0000002e;
}
.k-dashboard {
  display: flex;
}
.k-dashboard .k-panel-group {
  display: flex;
}
.k-dashboard .k-panel {
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.k-dashboard .k-panel > header {
  display: flex;
  flex-shrink: 0;
  align-items: stretch;
  padding: 0.5em 0.6em;
  border-bottom: 1px dotted #ccc;
  flex-wrap: wrap;
}
.k-dashboard .k-panel > header label {
  flex: 1;
  margin: 0;
  font-size: 1.2em;
  padding: 0.2em;
}
.k-dashboard .k-panel > header label .navbar-tag {
  font-weight: 100;
  line-height: inherit;
  vertical-align: bottom;
  opacity: 0.8;
}
.k-dashboard .k-panel > header .btn + label {
  margin-left: 0.2em;
}
.k-dashboard .k-panel > header aside.controls {
  display: flex;
  flex-wrap: wrap;
}
.k-dashboard .k-panel > header aside.controls a {
  font-size: 11px;
  line-height: 18px;
  margin-left: 0.3em;
}
.k-dashboard .k-panel > header aside.fixed-height-toggle {
  min-width: 2em;
  display: flex;
  margin-left: 0.5em;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #a7a7a7;
  cursor: pointer;
}
.k-dashboard .k-panel > header aside.fixed-height-toggle i.fa {
  margin: auto;
}
.k-dashboard .k-panel > header aside.fixed-height-toggle:hover {
  background-color: #e7e7e7;
  color: #7c7c7c;
}
.k-dashboard .k-panel > header aside.fixed-height-toggle.active {
  background-color: #dfdfdf;
  color: #161616;
  border-color: #b9b9b9;
}
.k-dashboard .k-panel > main {
  min-height: 0;
}
.k-dashboard .k-panel > main .k-linkset {
  display: flex;
}
.k-dashboard .k-panel > main .k-linkset .icon {
  margin-right: 0.2em;
}
.k-dashboard .k-panel > main footer.main {
  display: none;
}
.k-dashboard .k-panel > main .alert {
  margin-bottom: 0;
  border-bottom: none;
}
.k-dashboard .k-panel > main .form.edit-mode .form-footer {
  display: block;
}
.k-dashboard .k-panel > main .report-title {
  display: none;
}
.k-dashboard .k-panel > main .report-header {
  display: none;
}
.k-dashboard .k-panel > main .report-footer {
  display: none;
}
.k-dashboard .k-panel > main .report-table {
  border-top: none;
}
.k-dashboard .k-panel > main .report-table table {
  width: 100%;
  margin-bottom: 10px;
}
.k-dashboard .k-panel > main .report-table tr.report-total td,
.k-dashboard .k-panel > main .report-table tr.report-total-row td {
  font-weight: bold;
  font-size: 100%;
}
.k-dashboard .k-panel > main .chart-container {
  display: flex;
  height: 100%;
  text-align: center;
}
.k-dashboard .k-panel > main .chart-container .no-data {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.k-dashboard .k-panel > main .chart-container .no-data span,
.k-dashboard .k-panel > main .chart-container .no-data h3 {
  color: #ccc;
}
.k-dashboard .k-panel > main .chart-container .no-data span.fa {
  font-size: 3em;
}
.k-dashboard .k-panel > main .chart-container .no-data h3 {
  font-size: 1.2em;
  font-weight: normal;
}
.k-dashboard .k-panel > main .scroll-container {
  flex-grow: 1;
}
.k-dashboard .k-panel.iframe main {
  overflow: hidden;
  padding: 0;
}
.k-dashboard .k-panel.iframe main iframe {
  border: 0;
  width: 100%;
  height: 100%;
}
.k-dashboard .k-panel.link-list .nav.nav-pills {
  margin-bottom: 0;
}
.k-dashboard .k-panel.text main {
  opacity: 0.9;
}
.k-dashboard .k-panel.text main p {
  margin-bottom: 1em;
  line-height: 1.5em;
}
.k-dashboard .k-panel.text main h1,
.k-dashboard .k-panel.text main h2,
.k-dashboard .k-panel.text main h3,
.k-dashboard .k-panel.text main h4,
.k-dashboard .k-panel.text main h5,
.k-dashboard .k-panel.text main h6 {
  font-weight: normal;
  line-height: normal;
  margin-bottom: 0.4em;
}
.k-dashboard .k-panel.text main h1 {
  font-size: 1.1em;
}
.k-dashboard .k-panel.text main h2 {
  font-size: 1.2em;
}
.k-dashboard .k-panel.text main h3 {
  font-size: 1.3em;
}
.k-dashboard .k-panel.text main h4 {
  font-size: 1.4em;
}
.k-dashboard .k-panel.text main h5 {
  font-size: 1.5em;
}
.k-dashboard .k-panel.text main h6 {
  font-size: 1.6em;
}
.k-dashboard .k-panel.list {
  min-height: 12em;
}
.k-dashboard .k-panel.list section.main > .body {
  border: 0;
  padding: 0;
}
.k-dashboard .k-panel.report-query > main {
  overflow: initial;
}
.k-dashboard .k-panel.report-query section.main {
  min-height: 0;
}
.k-dashboard .k-panel.report-query .report-chart {
  overflow: auto;
}
.k-dashboard .k-panel.report-query .grid {
  padding: 1px;
}
.k-dashboard .k-panel.report-query .alert.empty {
  border: none;
  background: white;
  color: #888;
}
.k-dashboard .k-panel:where(:not(.form):not(.iframe)) > main {
  flex: 1;
  padding: 2px;
  overflow: auto;
}
.k-dashboard .k-panel:where(:not(.form):not(.iframe)) > main header.main {
  display: none;
}
.k-dashboard .k-panel:where(:not(.form):not(.iframe)) > main section.main {
  border: 0;
}
.k-dashboard .k-panel:where(:not(.form):not(.iframe)) > main section.main .form-body {
  padding: 0;
}
.k-dashboard .k-panel.list > main header.main {
  order: 1;
  display: flex;
  background: transparent;
}
.k-dashboard .k-panel.list > main header.main > label {
  opacity: 0;
}
.k-dashboard .k-panel.list > main header.main .btn {
  padding: 0 6px;
  font-size: 10.5px;
}
.k-dashboard .k-panel.list > main header.main .btn.help {
  display: none;
}
.k-dashboard .k-panel.parameter-form main form ul {
  display: flex;
}
.k-dashboard .k-panel.parameter-form main form ul li {
  flex: 1;
  padding: 0 5px;
  margin: 0;
}
.k-dashboard .k-panel.parameter-form main form ul li:first-child {
  padding-left: 0;
}
.k-dashboard .k-panel.parameter-form main form ul li:last-child {
  flex: 0;
  display: flex;
  align-items: flex-end;
  padding-right: 0;
}
.k-dashboard .k-panel.parameter-form main form ul li:last-child button {
  margin-bottom: 1px;
}
.k-dashboard .k-panel.parameter-form main form ul li:last-child button + button {
  margin-left: 5px;
}
.k-dashboard .k-panel.parameter-form main form ul li.required label {
  font-weight: bold;
}
.k-dashboard .k-panel.parameter-form main form ul li.required label em {
  color: red;
}
.k-dashboard .k-panel.parameter-form main form ul li:not(.required) label em {
  display: none;
}
.k-dashboard .k-panel.parameter-form main form .select2-button-container {
  display: none;
}
.k-dashboard .k-panel.parameter-form:not(.customize-mode) > header {
  display: none;
}
.k-dashboard .k-panel.databox-group {
  --size: 150px;
  --gap: 10px;
}
.k-dashboard .k-panel.databox-group main {
  display: flex;
  flex-wrap: wrap;
  padding: var(--gap);
}
.k-dashboard .k-panel.databox-group .databox {
  --padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: var(--size);
  width: var(--size);
  margin: var(--gap);
  box-shadow: 1px 0px 9px 0px #00000047;
  border-radius: 3px;
}
.k-dashboard .k-panel.databox-group .databox label {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  font-size: 15px;
  margin: 0;
  padding: var(--padding);
}
.k-dashboard .k-panel.databox-group .databox section {
  display: flex;
  flex-direction: column;
}
.k-dashboard .k-panel.databox-group .databox em {
  font-size: 38pt;
  font-style: normal;
  line-height: normal;
  padding: 0 var(--padding);
}
.k-dashboard .k-panel.databox-group .databox em.large {
  font-size: 32pt;
}
.k-dashboard .k-panel.databox-group .databox .list-name {
  font-size: 9pt;
  padding: var(--padding);
}
.k-dashboard .k-panel.databox-group .databox.dark {
  color: #ffffffeb;
}
.k-dashboard .k-panel.databox-group .databox.dark .list-name {
  background: #ffffff1c;
  color: #ffffffde;
}
.k-dashboard .k-panel.databox-group .databox.bright {
  border: 1px solid #00000014;
  color: #7c7c7c;
}
.k-dashboard .k-panel.databox-group .databox.bright .list-name {
  background: #ffffff52;
  color: #00000094;
}
.k-dashboard.sticky-header .k-panel-group:first-of-type {
  position: sticky;
  top: 0;
  z-index: 5;
}
.k-dashboard.sticky-header .k-panel-group:first-of-type .k-panel {
  margin: 0;
  margin-top: -10px;
  margin-bottom: 10px;
  background-color: #f0f0f0;
  border-radius: 0;
  border-color: #a7a7a7;
  border-width: 0 1px 1px;
}
header.dashboard-navigation {
  background-color: #f0f0f0;
  border-top: 1px solid #bfb9b9;
}
header.dashboard-navigation main {
  padding: 0.3em;
}
header.dashboard-navigation .k-linkset {
  display: flex;
}
.k-dashboard.horizontal {
  flex-direction: column;
}
.k-dashboard.horizontal .k-panel-group {
  flex-direction: row;
}
.k-dashboard.horizontal .k-panel-group .k-panel {
  flex: 1;
}
.k-dashboard.horizontal .k-linkset,
header.dashboard-navigation .k-linkset {
  flex-wrap: wrap;
}
.k-dashboard.horizontal .k-linkset span a,
header.dashboard-navigation .k-linkset span a {
  white-space: nowrap;
  display: inline-block;
  padding: 0.4em;
}
.k-dashboard.horizontal .k-linkset span::after,
header.dashboard-navigation .k-linkset span::after {
  content: '\00A0';
  display: inline-block;
  border-right: 1px solid #ccc;
  margin: 0 0.4em;
  width: 0;
}
.k-dashboard.horizontal .k-linkset span:last-child::after,
header.dashboard-navigation .k-linkset span:last-child::after {
  display: none;
}
@media screen and (max-width: 768px) {
  .k-dashboard.horizontal > .k-panel-group {
    flex-direction: column;
  }
  .k-dashboard.horizontal > .k-panel-group > .k-panel.tree {
    display: none;
  }
}
.k-dashboard.vertical {
  flex-direction: row;
  min-width: 250px;
}
.k-dashboard.vertical .k-panel-group {
  flex-direction: column;
  flex: 1;
}
.k-dashboard.vertical .k-panel-group .k-panel section.main input {
  min-width: 9em;
}
.k-dashboard.vertical .k-panel-group .k-panel .k-linkset {
  flex-direction: column;
}
.k-dashboard.vertical .k-panel-group .k-panel .k-linkset span a {
  display: block;
  padding: 0.7em 0;
  border-bottom: 2px groove rgba(255, 255, 255, 0.18);
}
.k-dashboard.vertical .k-panel-group .k-panel .k-linkset span:first-child a {
  padding-top: 0;
}
.k-dashboard.vertical .k-panel-group .k-panel .k-linkset span:last-child a {
  border: 0;
}
.k-dashboard.vertical .k-panel-group .k-panel.list header.main {
  justify-content: center;
}
.k-dashboard.vertical .k-panel-group .k-panel.list header.main label {
  display: none;
}
.k-dashboard.vertical .k-panel-group .k-panel.list section.main > .body {
  border-top: 0;
  padding: 0;
}
.k-dashboard.vertical .k-panel-group .k-panel.list .grid .actions {
  display: none;
}
.k-dashboard.vertical .k-panel-group .k-panel.list .grid th {
  background: whitesmoke;
}
.k-dashboard.vertical .k-panel-group .k-panel.list .grid th,
.k-dashboard.vertical .k-panel-group .k-panel.list .grid td {
  padding: 3px;
}
.dashboard-form {
  flex: 1;
  display: flex;
  overflow: hidden;
}
.dashboard-form .k-dashboard {
  flex: 1;
}
.dashboard-form .k-dashboard .k-panel-group {
  flex: 1;
  overflow: hidden;
}
.dashboard-form .k-dashboard .k-panel-group .k-panel > header label {
  opacity: 0.7;
}
.dashboard-form .k-dashboard .k-panel-group .k-panel.form > header {
  display: none;
}
.dashboard-form .k-dashboard .k-panel-group .k-panel.form > main {
  flex: 1;
  background: white;
  display: flex;
  overflow: auto;
}
.dashboard-form .k-dashboard .k-panel-group .k-panel.tree {
  position: relative;
  border-right: 1px solid #c4c4c4;
}
.dashboard-form .k-dashboard .k-panel-group .k-panel.tree > header {
  display: none;
}
.dashboard-form .k-dashboard .k-panel-group .k-panel.tree > main {
  flex: 1;
  display: flex;
  padding: 0;
}
.dashboard-form .k-dashboard .k-panel-group .k-panel.tree .tree-container {
  overflow: auto;
}
.dashboard-form .k-dashboard .k-panel-group .k-panel.tree .tree-container > aside.controls {
  padding: 0.3em;
  border-bottom: 1px solid #c7c7c7;
  background: #fdfcfc;
}
.dashboard-form .k-dashboard .k-panel-group .k-panel.tree .tree-container > aside.controls button {
  padding: 0.4em;
}
.dashboard-form .k-dashboard .k-panel-group .k-panel.tree .tree-container .tree {
  padding: 10px;
  box-shadow: inset 0 0 15px -6px black;
}
.dashboard-form .k-dashboard .k-panel-group .k-panel.tree .tree-container .tree .k-in .heading {
  text-transform: uppercase;
}
#main-content > .k-dashboard,
.dashboard-form > .k-dashboard {
  background-color: #f0f0f0;
  min-height: 0;
  min-width: 0;
}
#main-content > .k-dashboard .k-panel > header,
.dashboard-form > .k-dashboard .k-panel > header {
  background: #fdfcfc;
  border: 1px solid #ccc;
  border-width: 1px 0;
  box-shadow: 0 0px 5px -3px black;
}
#main-content > .k-dashboard .k-panel .grid,
.dashboard-form > .k-dashboard .k-panel .grid {
  background-color: rgba(255, 255, 255, 0.88);
}
#main-content > .k-dashboard + form.form,
.dashboard-form > .k-dashboard + form.form {
  border: 1px solid #b3b3b3;
  box-shadow: 0 0 9px -4px black;
}
#main-content > .k-dashboard + form.form .decision,
.dashboard-form > .k-dashboard + form.form .decision {
  box-shadow: none;
}
.dashboard-customize-menu {
  margin-top: 0.6rem;
  margin-right: 0.6rem;
}
#root-layout.dashboard.chromeless {
  flex-direction: column;
}
#root-layout.dashboard.chromeless > #main-content .k-dashboard {
  background: none;
}
#root-layout.dashboard.chromeless > #main-content .k-panel > header .controls {
  display: none;
}
#root-layout.dashboard.chromeless > #main-content .k-panel:not(.form):not(.list):not(.report-query) > main section.main {
  border: 0;
  overflow: unset;
}
#root-layout.dashboard.chromeless > #main-content .grid {
  border: 1px solid gainsboro;
}
#root-layout.dashboard.chromeless > #main-content .grid .actions {
  display: none;
}
#root-layout.dashboard.chromeless > #main-content .grid .grid-buttons {
  display: none;
}
body.modal-frame #root-layout.dashboard.chromeless #main-content {
  padding-top: 10px;
  display: block;
}
body.modal-frame #root-layout.dashboard.chromeless #main-content .k-panel form.form {
  min-height: unset;
  height: 100%;
}
body.modal-frame #root-layout.dashboard.chromeless #main-content .k-panel form.form header.main {
  border: unset;
  padding: unset;
}
body.modal-frame #root-layout.dashboard.chromeless #main-content .k-dashboard.sticky-header .k-panel-group:first-of-type .k-panel {
  border-bottom: 1px solid #cbcbcb;
  box-shadow: 0 0 7px 0px #00000040;
}
